/**
 * Defines actions and action creators related to the product cart.
 */

import { addFlashMessage } from '~/actions/flash-message'
import { loadGroup } from '~/actions/group'
import { alertMinorConsent, resetMinorConsent } from '~/actions/minor-consent'
import { push } from '~/actions/navigation'
import { loadProfile } from '~/actions/profile'
import { loadProfileProducts } from '~/actions/profile-products'
import { ALERT_TERMS, RESET_TERMS } from '~/actions/terms-and-conditions'
import { ReduxInternalInitAction } from '~/actions/types'
import {
  updateCartStatus,
  updateCartFailureMessage,
  signWaiverStatus,
  SigningWaiverState,
  startUpdatePromoCode,
  stopUpdatePromoCode,
  startUpdatePassAssignment,
  stopUpdatePassAssignment,
} from '~/actions/ui/cart'
import {
  startAddUpgrades,
  stopAddUpgrades,
  showUpgradesAdded,
} from '~/actions/ui/upgrades'
import deserializeCart from '~/deserializers/cart'
import { i18n } from '~/i18n'
import getAPICore, { getCustomerAPI } from '~/utils/api'
import { requestUpdateCart, postCartRenew } from '~/utils/api/cart'
import { createMinorConsents } from '~/utils/api/minor-consent'
import changeLocation from '~/utils/change-location'
import { getErrorMessage } from '~/utils/credit-card'
import track from '~/utils/enhanced-commerce-tracking'
import { authedFetch } from '~/utils/fetch'
import { install } from '~/utils/form-data-polyfill'
import HTTPError from '~/utils/http-error'
import { getErrorMessageFromHTTPError } from '~/utils/http-error-utils'
import { locationWithLocale } from '~/utils/locale'
import { reportGroupedError } from '~/utils/logger'

import { loadCart } from './load-cart'
import { updateCart } from './update-cart'

import type { AppState } from '~/reducers'
import type {
  API,
  CartItem,
  CartState,
  Currency,
  Locale,
  Product,
  ProductVariant,
} from '~/types'

install()
type Dispatch = (...args: Array<any>) => any
type GetState = () => AppState

export * from './load-cart'
export * from './update-cart'

export type CartActions =
  | ReduxInternalInitAction
  | {
      type: 'UPDATE_CART'
      cart: CartState
    }
  | {
      type: 'CLEAR_CART'
    }
  | {
      type: 'UPDATE_SHEER_ID_STATUS'
      internalId: number
      orgType: string
      status: string
    }

//
// - Actions and Sync Action Creators
//

export const CLEAR_CART = 'CLEAR_CART'
export function clearCart(): CartActions {
  return {
    type: CLEAR_CART,
  }
}

//
// - Async Action Creators
//
export function signWaiver(
  resortAuthorizationCategoryId: number | null | undefined,
  profileIds: string[],
) {
  return async (dispatch: Dispatch) => {
    if (!resortAuthorizationCategoryId) return

    const body = JSON.stringify({
      data: {
        profile_ids: profileIds,
      },
    })
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body,
    }

    try {
      dispatch(signWaiverStatus(SigningWaiverState.PROCESSING))
      const resp = await authedFetch(
        `/api/v2/cart/waivers/${resortAuthorizationCategoryId}`,
        options,
      )

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(signWaiverStatus(SigningWaiverState.SUCCESS))
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Signing the waiver failed', resp, text)
      }
    } catch (error) {
      dispatch(signWaiverStatus(SigningWaiverState.FAILED))
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.default_error'),
        ),
      )
      reportGroupedError('signWaiver', error)
    }
  }
}

/**
 * createRenewCart makes a request to the server to create a renew cart for the authenticated guest
 *
 *
 * @param  {Number} internalId      The profile internal id to update.
 * @param  {Object} data            The profile data.
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
export function createRenewCart() {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await postCartRenew()

      if (resp.ok) {
        const { data } = await resp.json()

        const cart = deserializeCart(data)
        track.add(cart)

        dispatch(updateCart(cart))
        dispatch(push('/cart'))
      } else {
        const text = await resp.text()
        throw new HTTPError(
          'Attempt to add renew products to cart failed',
          resp,
          text,
        )
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.renew_error'),
        ),
      )
      reportGroupedError('createRenewCart', error)
    }
  }
}

export function addUpgradesToCart(
  data: Record<number, string>,
  api: API = getCustomerAPI(),
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startAddUpgrades())
      const resp = await api.addUpgradesToCart(data)

      if (resp.ok) {
        const { data } = await resp.json()

        const cart = deserializeCart(data)
        track.add(cart)

        dispatch(updateCart(cart))
        dispatch(stopAddUpgrades())
        dispatch(showUpgradesAdded())
      } else {
        const text = await resp.text()
        throw new HTTPError(
          'Attempt to add upgrade products to cart failed',
          resp,
          text,
        )
      }
    } catch (error) {
      dispatch(stopAddUpgrades())
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.upgrade_error'),
        ),
      )
      reportGroupedError('addUpgradesToCart', error)
    }
  }
}

export function createUpgradeCart(
  profileProductIds: string[],
  redirectPath = '/cart',
  api: API = getCustomerAPI(),
) {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await api.upgradeCart(profileProductIds)

      if (resp.ok) {
        const { data } = await resp.json()

        const cart = deserializeCart(data)
        track.add(cart)

        dispatch(updateCart(cart))
        dispatch(push(redirectPath))
      } else {
        const text = await resp.text()
        throw new HTTPError(
          'Attempt to add upgrade products to cart failed',
          resp,
          text,
        )
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.upgrade_error'),
        ),
      )
      reportGroupedError('createUpgradeCart', error)
    }
  }
}

/**
 * updateCartChanged makes a request to the server to update a cart changed flag.
 *
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
export function updateCartChanged(api: API = getCustomerAPI()) {
  return async (dispatch: Dispatch) => {
    try {
      const resp = await api.acknowledgeCartChange()

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Cart changed update failed', resp, text)
      }
    } catch (error) {
      dispatch(updateError())
      reportGroupedError('updateCartChanged', error)
    }
  }
}

/**
 * updateCartItemProduct makes a request to the server to update a cart item.
 *
 *
 * @param  {Object} item            The cart item to update.
 * @param  {Object} productVariant  The product variant.
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
export function updateCartItemProduct(
  item: CartItem,
  productVariant: ProductVariant,
) {
  return async (dispatch: Dispatch) => {
    const body = JSON.stringify({
      data: {
        type: productVariant.productId,
        variant: productVariant.ageCategory,
      },
    })
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body,
    }

    try {
      const resp = await authedFetch(
        `/api/v2/cart/items/${item.internalId}`,
        options,
      )

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Cart item update failed', resp, text)
      }
    } catch (error) {
      dispatch(updateError())
      reportGroupedError('updateCartItemProduct', error)
    }
  }
}

/**
 * changeCartParticipant makes a request to the server to update the profile id
 * of a profile in the cart.
 *
 *
 * @param  {String} internalId      The item internal id.
 * @param  {String} profileId       The new profile id.
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
export function changeCartParticipant(
  internalId: string,
  profileId: string | null | undefined,
  api: API = getCustomerAPI(),
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(startUpdatePassAssignment())
      const resp = await api.changeCartParticipant(internalId, profileId)

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else if (resp.status === 422) {
        const { errors } = await resp.json()

        if (errors.internal_id) {
          dispatch(
            addFlashMessage(
              'error',
              i18n.t('pages.pass_assignment.item_unknown'),
            ),
          )
        } else {
          dispatch(
            addFlashMessage(
              'error',
              i18n.t('pages.pass_assignment.age_validation_message'),
            ),
          )
        }
      } else {
        const text = await resp.text()
        throw new HTTPError('Cart item assignment failed', resp, text)
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.assign_error'),
        ),
      )
      reportGroupedError('changeCartParticipant', error)
    } finally {
      dispatch(stopUpdatePassAssignment())
    }
  }
}

/**
 * updateCartItemCount makes a request to the server to update the count of a
 * specific item in the cart.
 *
 *
 * @param  {Object} productVariant  The product variant in question.
 * @param  {Number} count           The new count.
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
let updateCartItemRequestCount = 0
export function updateCartItemCount(
  productVariant: ProductVariant,
  count: number,
  api: API = getCustomerAPI(),
) {
  return async (dispatch: Dispatch) => {
    try {
      const requestCount = ++updateCartItemRequestCount
      const resp = await api.updateCartItemCount(productVariant, count)

      if (resp.ok) {
        if (requestCount < updateCartItemRequestCount) return
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Cart item update failed', resp, text)
      }
    } catch (error) {
      const message = getErrorMessageFromHTTPError(
        error,
        i18n.t('components.flash_messages.update_error'),
        {
          pickKeys: ['cart_item_purchase_group_mismatch'],
        },
      )

      dispatch(updateError(message))
      reportGroupedError('updateCartItemCount', error)
    }
  }
}

/**
 * Make a request to server to add/remove a product for a given profile,
 * or all eligible profiles if profile ID is not specified.
 *
 * @param  {Boolean}  state       Whether to add the product (true), or remove (false).
 * @param  {Product}  product     Product to add/remove from profile.
 * @param  {String}   profileId   Optional ID of profile to add product to.
 *
 * @return {Function} Thunk which will initiate the request to the server.
 */
export function toggleProduct(
  state: boolean,
  product: Product,
  profileId?: string,
) {
  return async (dispatch: Dispatch) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: state ? 'POST' : 'DELETE',
      body: JSON.stringify({
        data: {
          type: product.id,
        },
      }),
    }
    const actionText = state ? 'add' : 'remove'
    let url
    if (profileId) url = `/api/v2/cart/profiles/${profileId}/items`
    else url = '/api/v2/cart/items'

    try {
      const resp = await authedFetch(url, options)

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError(`${actionText} item failed`, resp, text)
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          `Unable to ${actionText} the addon. Please try again.`,
        ),
      )
      reportGroupedError('toggleProduct', error)
    }
  }
}

/**
 * removeCartItem makes a request to the server to remove a specific item from the cart.
 *
 *
 * @param  {String} internalId  The internalId of the item to remove
 *
 * @return {Function}               Thunk which will initiate the request to the
 *                                  server.
 */
export function removeCartItem({
  internalId,
  api = getCustomerAPI(),
}: {
  internalId: string
  api?: API
}) {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      const resp = await api.removeCartItem(internalId)

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        const { cart: oldCart } = getState()
        track.remove(oldCart, cart)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Removing cart item failed', resp, text)
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.remove_item_error'),
        ),
      )
      reportGroupedError('removeCartItem', error)
    }
  }
}

/**
 * purchasePasses makes a request to the server to buy the passes provided.
 *
 * It combines the information in the store for the cart with the payment
 * information provided.
 *
 * @param  {Object} paymentInfo The information related to payment which
 *                              is not kept in the store for security reasons.
 * @return {Function}           Thunk which will initiate the request to the
 *                              server.
 */
let purchasing = false

type PurchasePassesProps = { minorConsentSatisfied: boolean }
export function purchasePasses({ minorConsentSatisfied }: PurchasePassesProps) {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (purchasing) return

    const api = getCustomerAPI()
    const state = getState()
    const termsAcceptedAt = state.termsAndConditions.acceptedAt

    if (!termsAcceptedAt) {
      dispatch({
        type: ALERT_TERMS,
      })
    }

    if (!minorConsentSatisfied) {
      dispatch(alertMinorConsent(true))
    }

    if (!termsAcceptedAt || !minorConsentSatisfied) {
      return
    }

    purchasing = true

    dispatch(updateCartFailureMessage())
    dispatch(updateCartStatus('PROCESSING'))

    try {
      if (state.cart.profileIdsRequiringMinorConsent.length > 0) {
        await createMinorConsents(state.cart.profileIdsRequiringMinorConsent)
      }

      const resp = await api.createOrder(termsAcceptedAt)
      purchasing = false

      if (resp.ok) {
        dispatch({
          type: RESET_TERMS,
        })
        dispatch(resetMinorConsent())

        const { data } = await resp.json()
        const { id } = data

        dispatch(handleSuccessfulOrder(state.cart, id, false))
      } else if (resp.status === 422) {
        const { errors } = await resp.json()
        const errorMessages = Object.values(errors)[0]
        const errorMessage = Array.isArray(errorMessages)
          ? errorMessages[0]
          : undefined

        if (
          Object.keys(errors).includes('order') &&
          errorMessage === "Can't be empty."
        ) {
          dispatch(updateCartFailureMessage(errorMessage))
          dispatch(updateCartStatus('FAILED'))
          dispatch(push('/cart'))

          dispatch(
            addFlashMessage(
              'error',
              i18n.t('components.flash_messages.empty_order_error'),
              {
                id: 'cart-error',
                navCount: 0,
              },
            ),
          )
        } else if (Object.keys(errors).includes('unacknowledged_change')) {
          dispatch(loadCart())
          dispatch(updateCartStatus('FAILED'))
          dispatch(push('/payment#payment_method'))
        } else if (Object.keys(errors).includes('reason_code')) {
          dispatch(updateCartFailureMessage(getErrorMessage(errors)))
          dispatch(push('/payment#payment_method'))
        } else if (typeof errorMessage === 'string') {
          dispatch(
            updateCartFailureMessage(
              i18n.t('components.errors.orders.generic'),
            ),
          )
          dispatch(updateCartStatus(''))
          dispatch(push('/payment#payment_method'))
        } else {
          dispatch(updateCartStatus('FAILED'))
        }
      } else {
        const message = 'payment failed'
        const rawBody = await resp.text()
        throw new HTTPError(message, resp, rawBody)
      }
    } catch (error) {
      reportGroupedError('purchasePasses', error)
      dispatch(updateCartStatus('FAILED'))
    }

    purchasing = false
  }
}

export function handleSuccessfulOrder(
  cart: CartState,
  orderId: number,
  affirmPayment: boolean,
) {
  return async (dispatch: Dispatch) => {
    track.purchase(cart, orderId, affirmPayment)
    dispatch(
      loadProfile({
        reload: true,
      }),
    )
    dispatch(
      loadGroup({
        reload: true,
      }),
    )
    dispatch(
      loadProfileProducts({
        reload: true,
      }),
    )
    dispatch(updateCartStatus('PROCESSING'))
    dispatch(push(`/myaccount/orders/${orderId}`))
  }
}

export function addPromoCode(code: string, api: API = getCustomerAPI()) {
  return async (dispatch: Dispatch) => {
    dispatch(startUpdatePromoCode())

    await performUpdateCartRequest(
      () => api.addCartPromoCode(code),
      'Apply promo failed',
    )(dispatch)
    dispatch(stopUpdatePromoCode())
  }
}

export function removePromoCode(code: string, api: API = getCustomerAPI()) {
  return async (dispatch: Dispatch) => {
    dispatch(startUpdatePromoCode())

    await performUpdateCartRequest(
      () => api.removeCartPromoCode(code),
      'Remove promo failed',
    )(dispatch)
    dispatch(stopUpdatePromoCode())
  }
}

/**
 * setAddonForPass either adds or removes an addon for a single pass.
 *
 * @param  {Boolean} state        Whether to add (true) or remove (false) insurance.
 * @param  {id} internalId       Internal id of the item
 * @return {Function}             Thunk which will initiate the request to
 *                                the server.
 */
export function setAddonForPass(
  state: boolean,
  internalId: string,
  addonType: string,
  api: API = getCustomerAPI(),
) {
  return async (dispatch: Dispatch) => {
    const verb = state ? 'add' : 'remove'

    try {
      let resp

      if (state) {
        resp = await api.addCartAddon(internalId, addonType)
      } else {
        resp = await api.removeCartAddon(internalId, addonType)
      }

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else if (resp.status === 422) {
        const { errors } = await resp.json()

        if (errors.id) {
          dispatch(
            addFlashMessage('error', i18n.t('pages.cart.addon_item_unknown')),
          )
        } else {
          throw new HTTPError(`Failed to ${verb} insurance`, resp, errors)
        }
      } else {
        const text = await resp.text()
        throw new HTTPError(`Failed to ${verb} insurance`, resp, text)
      }
    } catch (error) {
      dispatch(updateError())
      reportGroupedError('setAddonForPass', error, {
        message: `${verb} error`,
      })
    }
  }
}

export function setCurrencyAndLocale(currency: Currency, locale: Locale) {
  return async (dispatch: Dispatch) => {
    const dataParams = {
      payload_currency: currency,
    }

    try {
      const resp = await requestUpdateCart(dataParams)

      if (resp.ok) {
        changeLocation(locationWithLocale(locale))
      } else {
        const text = await resp.text()
        throw new HTTPError(`Failed to PATCH cart`, resp, text)
      }
    } catch (error) {
      dispatch(updateError())
      reportGroupedError('setCurrencyAndLocale', error)
    }
  }
}

const updateError = (
  message: string = i18n.t('components.flash_messages.update_error'),
) => addFlashMessage('error', message)

function performUpdateCartRequest(
  request: () => Promise<Response>,
  errorMessage = 'Failed to update cart',
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await request()

      if (response.status === 200 || response.status === 422) {
        const cart = deserializeCart(await response.json())
        dispatch(updateCart(cart))
      } else {
        throw new HTTPError(errorMessage, response, await response.text())
      }
    } catch (error) {
      reportGroupedError('performUpdateCartRequest', error)
    }
  }
}

export function setAccessDisclaimer(accessDisclaimer: boolean) {
  return async (dispatch: Dispatch) => {
    const api = getAPICore(authedFetch)
    const options = {
      body: JSON.stringify({
        data: {
          access_disclaimer: accessDisclaimer,
        },
      }),
    }

    try {
      const resp = await api.post(`/api/v2/cart/access-disclaimer`, options)

      if (resp.ok) {
        const { data } = await resp.json()
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        const text = await resp.text()
        throw new HTTPError('Signing the access disclaimer failed', resp, text)
      }
    } catch (error) {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.default_error'),
        ),
      )
      reportGroupedError('setAccessDisclaimer', error)
    }
  }
}
